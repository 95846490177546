// Ingredients Block
.ingredients-block {
  background: url(../img/ingredients-bg.jpg) 0 0 no-repeat;
  background-size: cover;
}
.feature-image-group {
  padding-top: 20px;
  text-align: center;
}
.feature-image {
  display: inline-block;
  margin: 0 5px;
  border: 5px solid $copy-grey;
  border-radius: 100%;
  width: 100px;
  height: 100px;
}


// Ingredients Block Animations
.ingredients-block.active {
  @include animate;
  animation-delay: 0.2s;
  @extend .fade-in;
  .feature-box {
    @include animate;
    animation-delay: 0.4s;
    @extend .fade-in;
    h2 {
      @include animate;
      animation-delay: 0.6s;
      @extend .fade-in-down;
    }
    p {
      @include animate;
      animation-delay: 0.6s;
      @extend .fade-in-up;
    }
    .feature-image {
      @include animate;
      @extend .fade-in;
    }
    .feature-image-1 { animation-delay: 0.2s; }
    .feature-image-2 { animation-delay: 0.4s; }
    .feature-image-3 { animation-delay: 0.6s; }
  }
}